import React, { useEffect, useRef } from "react";
import Chart from "react-apexcharts";

const ApexChart = ({ options, series, type, width, onClick }) => {
    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current && onClick) {
            const chartElement = chartRef.current.chart;

            // click event listener
            chartElement.addEventListener("click", onClick);

            // Cleanup the event listener when the component is unmounted
            return () => {
                chartElement.removeEventListener("click", onClick);
            };
        }
    }, [onClick]);

    return (
        <Chart
            ref={chartRef}
            options={options}
            series={series}
            type={type}
            width={width}
        />
    );
};

export default ApexChart;

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./AnalyticsPage.css";
import api from "../../api/api";
import Loader from "../Loader/Loader";
import ApexChart from "./Chart/ApexChart";
import html2pdf from "html2pdf.js";

const tabs = [
    "Accessibility Insights",
    "Content Summary",
    "Extracted Text",
    "Links",
    "QR Data",
];

const AnalyticsPage = () => {
    const [currentTab, setCurrentTab] = useState(tabs[0]);
    const [reportData, setReportData] = useState({});
    const [loading, setLoading] = useState(true);
    const [apexChartOptions, setApexChartOptions] = useState({});
    const [dataFetched, setDataFetched] = useState(false);
    const [apexChartOptionsContrast, setApexChartOptionsContrast] = useState({
        chart: {
            id: "contrast-score-chart",
            series: [0, 100], // Set default values or an empty array
            labels: ["Score", "Remaining"],
        },
    });
    const [apexChartOptionsText, setApexChartOptionsText] = useState({
        chart: {
            id: "text-score-chart",
            series: [0, 100], // Set default values or an empty array
            labels: ["Score", "Remaining"],
        },
    });

    const containerRef = useRef(null);
    // PDF downlaod
    const handleDownload = () => {
        if (containerRef.current) {
            const content = containerRef.current.outerHTML;

            // Create a div with a light orange background
            const wrapper = document.createElement("div");
            wrapper.innerHTML = content;
            wrapper.style.backgroundColor = "#FFEBCD"; // Light orange color

            // Convert the div content to PDF using html2pdf
            html2pdf(wrapper, {
                margin: 10,
                filename: "accessibility_insights_report.pdf",
                jsPDF: { unit: "mm", format: "a3", orientation: "portrait" },
            });
        }
    };
    // for accesiblity chart
    const [chartOptions, setChartOptions] = useState({
        labels: ["Accessibility score", "Remaining"],
        colors: ["#008FFB", "#DADADA"],
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                donut: {
                    size: "80%",
                    background: "transparent",
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: "7/10",
                            color: "#000000",
                            fontSize: "16px",
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b;
                                }, 0);
                            },
                        },
                    },
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    });
    const [showAdditionalContent, setShowAdditionalContent] = useState(false);

    const handleChartClick = () => {
        console.log("clicked");
        setShowAdditionalContent(true);
    };
    async function fetchData() {
        try {
            setLoading(true);
            const report = await api.getReport();
            if (report && report.report) {
                setReportData(report);
                if (report.report[5]?.data) {
                    // Extracting color data for the pie chart
                    const colorData = report.report[5].data;
                    const colors = colorData.map((item) => item[0]);
                    const percentages = colorData.map((item) => item[1]);

                    const options = {
                        chart: {
                            type: "donut",
                        },
                        series: percentages,
                        colors: colors,
                    };

                    setApexChartOptions(options);
                    // Color Contrast chart
                    // Extracting contrast rating percentage from the report
                    const contrastRatingPercentage = report.report.find(
                        (item) => item.sectionName === "Contrast Rating"
                    )?.data;

                    // Rounding the percentage and ensuring the sum is 100
                    const roundedPercentage = Math.min(
                        95,
                        Math.round(contrastRatingPercentage)
                    );

                    // Calculating series values to ensure the sum is always 100
                    const seriesValue =
                        roundedPercentage > 100 ? 100 : roundedPercentage;
                    const remainingValue = 100 - seriesValue;
                    // Creating contrastScoreOptions with the rounded and adjusted values
                    const contrastScoreOptions = {
                        chart: {
                            id: "contrast-score-chart",

                            type: "donut",
                        },
                        series: [seriesValue, remainingValue],
                        labels: ["Score", "Remaining"],
                    };
                    console.log(options, "options");
                    console.log(contrastScoreOptions, "options");
                    setApexChartOptionsContrast(contrastScoreOptions);

                    // Text Quality chart
                    const textQualityData = report.report.find(
                        (item) => item.sectionName === "Text Quality"
                    )?.data;

                    const fontScore = textQualityData
                        ? textQualityData["Font Score"]
                        : 0;

                    const seriesValueText = fontScore;
                    const remainingValueText = 100 - seriesValueText;

                    // Creating TextScoreOptions with the rounded and adjusted values
                    const TextScoreOptions = {
                        chart: {
                            id: "text-score-chart", // Corrected chart id
                            type: "donut",
                        },
                        series: [seriesValueText, remainingValueText],
                        labels: ["Score", "Remaining"],
                    };

                    setApexChartOptionsText(TextScoreOptions);

                    const averagePercentage =
                        (seriesValue + seriesValueText) / 2;
                    const averageSeriesValue = Math.min(
                        100,
                        Math.max(0, Math.round(averagePercentage))
                    );
                    const averageRemainingValue = 100 - averageSeriesValue;

                    // Update the Overall Score chart options
                    const overallScoreOptions = {
                        chart: {
                            id: "overall-score-chart",
                            type: "donut",
                        },
                        series: [averageSeriesValue, averageRemainingValue],
                        labels: ["Score", "Remaining"],
                    };

                    setChartOptions(overallScoreOptions);

                    // Set the dataFetched flag to true
                    setDataFetched(true);
                }
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (Object.keys(reportData).length === 0) {
            fetchData();
        }
    }, [reportData]);

    const options = {
        chart: {
            id: "my-chart",
        },
        colors: ["red", "pink", "white", "yellow"],
        xaxis: {},
        yaxis: {
            title: "Sales",
        },
    };

    const series = [20, 30, 50];

    return (
        <div className="analytics-page" ref={containerRef}>
            {!showAdditionalContent && dataFetched && (
                <div className="accessibility-section">
                    Overall Score
                    <ApexChart
                        options={chartOptions}
                        series={chartOptions.series || [7, 3]}
                        type="donut"
                        width="480"
                        onClick={handleChartClick}
                    />
                </div>
            )}
            {showAdditionalContent && (
                <div>
                    <div className="tab-navigation">
                        {tabs.map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setCurrentTab(tab)}
                                className={tab === currentTab ? "active" : ""}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>

                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="tab-content">
                            {currentTab === "Content Summary" &&
                                reportData.report && (
                                    <div className="content-summary">
                                        <h3>Content Summary</h3>
                                        <p>
                                            {reportData.report[0]?.data[0] ||
                                                "No data"}
                                        </p>
                                        <h3>Frequent Words:</h3>
                                        <table className="frequent-words-table">
                                            <thead>
                                                <tr>
                                                    <th>Word</th>
                                                    <th>Frequency</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportData.report[9]?.data.map(
                                                    (word, index) => (
                                                        <tr key={index}>
                                                            <td>{word[0]}</td>
                                                            <td>{word[1]}</td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            {currentTab === "Extracted Text" &&
                                reportData.report && (
                                    <div className="extracted-text">
                                        <h3>Extracted Text</h3>
                                        <p className="extracted-text-content">
                                            {reportData.report[1]?.data[0] ||
                                                "No data"}
                                        </p>
                                    </div>
                                )}
                            {currentTab === "Links" && reportData.report && (
                                <div className="link-validation">
                                    <h3>Hyperlinks</h3>
                                    {reportData.report.map((item) => {
                                        if (
                                            item.sectionName ===
                                                "Link Validation" &&
                                            item.data.length > 0
                                        ) {
                                            return (
                                                <table key="linkValidationTable">
                                                    <thead>
                                                        <tr></tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.data.map(
                                                            (
                                                                linkItem,
                                                                index
                                                            ) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {
                                                                            linkItem.link
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            )}
                            {currentTab === "Accessibility Insights" && (
                                <div className="accessibility-insights">
                                    <h3>Accessibility Insights</h3>
                                    <div className="accessibility-section">
                                        <h4>Color Analysis</h4>
                                        <div className="accessibility-section">
                                            {/* <ColorAnalysisTable
                                        data={reportData.report[5]?.data}
                                    /> */}
                                            <div>
                                                {/* options={options}
                                        series={series} */}
                                                <ApexChart
                                                    className="chart"
                                                    options={apexChartOptions}
                                                    series={
                                                        apexChartOptions.series
                                                    }
                                                    type="donut"
                                                    width="400"
                                                />
                                            </div>
                                        </div>

                                        <div className="contrast-score-chart">
                                            <h4>Contrast Score</h4>

                                            <ApexChart
                                                options={
                                                    apexChartOptionsContrast
                                                }
                                                series={
                                                    apexChartOptionsContrast.series
                                                }
                                                type="donut"
                                                height="150"
                                            />
                                        </div>
                                    </div>
                                    <div className="accessibility-section">
                                        <h4>Image Quality</h4>

                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Resolution:</td>
                                                    <td>
                                                        {reportData.report[3]
                                                            ?.data.Resolution ||
                                                            "No data"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Sharpness:</td>
                                                    <td>
                                                        {
                                                            reportData.report[3]
                                                                ?.data.Sharpness
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Contrast:</td>
                                                    <td>
                                                        {
                                                            reportData.report[3]
                                                                ?.data.Contrast
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <h4>Image Quality Score</h4>
                                        <div className="image-quality-chart">
                                            <ApexChart
                                                options={{
                                                    chart: {
                                                        id: "image-quality-chart",
                                                    },
                                                }}
                                                series={[imageQualityRating]}
                                                type="radialBar"
                                                height="150"
                                            />
                                        </div> */}
                                    </div>
                                    <div className="accessibility-section">
                                        <h4>Text Quality</h4>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Font Used:</td>
                                                    <td>
                                                        {
                                                            reportData.report[4]
                                                                ?.data[
                                                                "Font Used"
                                                            ]
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Text Size:</td>
                                                    <td>
                                                        {
                                                            reportData.report[4]
                                                                ?.data[
                                                                "Text Size"
                                                            ]
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Recommendation:</td>
                                                    <td>
                                                        {
                                                            reportData.report[4]
                                                                ?.data
                                                                .Recommendation
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h4>Text Quality Score</h4>

                                        <div className="text-quality-chart">
                                            <ApexChart
                                                options={apexChartOptionsText}
                                                series={
                                                    apexChartOptionsText.series
                                                }
                                                type="donut"
                                                height="150"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {currentTab === "QR Data" && (
                        <div className="QR-to-link">
                            <h3>QR Data</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>QR</th>
                                        <th>Link or Information</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData.report &&
                                        reportData.report.map((item, index) => {
                                            if (
                                                item.sectionName === "QR Data"
                                            ) {
                                                const qrData = item.data;

                                                return (
                                                    <tr key={index}>
                                                        <td>{`QR${
                                                            index + 1
                                                        }`}</td>
                                                        <td>
                                                            <a
                                                                href={qrData}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {qrData}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            return null;
                                        })}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {currentTab === "Accessibility Insights" && !loading && (
                        <div className="pie-chart">
                            {/* <ApexChart colors={ApexChartColors} /> */}
                        </div>
                    )}

                    <div className="centered-button-container">
                        <button
                            className="generate-report"
                            onClick={() => setShowAdditionalContent(false)}
                        >
                            Back to Score
                        </button>
                        <button
                            className="generate-report"
                            onClick={handleDownload}
                        >
                            Download
                        </button>

                        <Link to="/report">
                            <button className="generate-report">
                                Generate Report
                            </button>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AnalyticsPage;

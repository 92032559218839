import React from "react";
import "./HomePage.css";
import poster from "../../PostersStockImage.jpeg";
import logo from "./poster.png";

const HomePage = () => {
    return (
        <div className="home-page">
            <div className="left-section">
                <h1>
                    Welcome to Poster Insights!{" "}
                    <img src={logo} alt="Logo" width="30" height="30" />
                </h1>

                <p>Optimize Your Poster Designs with Poster Insights</p>
                <ul>
                    <li>Analyze text content, font size, and image quality.</li>
                    <li>
                        extract links and check QR codes for additional
                        information.
                    </li>
                    <li>
                        Get detailed reports on content summary, image quality,
                        and more.
                    </li>
                </ul>

                <p>
                    Explore useful resources for WCAG color standards and
                    contrast checking:
                    <ul>
                        <li>
                            <a
                                href="https://www.w3.org/WAI/WCAG21/quickref/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                WCAG Quick Reference
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://webaim.org/resources/contrastchecker/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                WebAIM Contrast Checker
                            </a>
                        </li>
                    </ul>
                </p>
            </div>
            <div className="right-section">
                <img src={poster} alt="Poster image" />
            </div>
            <footer>{/* Add your footer content here */}</footer>
        </div>
    );
};

export default HomePage;

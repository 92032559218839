import React, { useState } from "react";
import "./FeedbackPage.css";

const FeedbackPage = () => {
    const [feedback, setFeedback] = useState("");
    const [rating, setRating] = useState(0);
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

    const submitFeedback = () => {
        // Check if feedback and rating are filled before submitting
        if (feedback && rating > 0) {
            // Log the feedback (no API call in this example)
            console.log("Feedback:", feedback);
            console.log("Rating:", rating);

            // Set the flag to show the pop-up
            setIsFeedbackSubmitted(true);

            // Reset the form fields
            setFeedback("");
            setRating(0);
        }
    };

    const closePopup = () => {
        // Close the pop-up and reset the flag
        setIsFeedbackSubmitted(false);
    };

    return (
        <div className="feedback-page">
            <h1>Image Analysis Feedback</h1>
            <form>
                <div>
                    <label htmlFor="feedback">Your Feedback:</label>
                    <textarea
                        id="feedback"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="rating">Rating (1-5):</label>
                    <input
                        type="number"
                        id="rating"
                        min="1"
                        max="5"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                    />
                </div>
                <button
                    type="button"
                    onClick={submitFeedback}
                    disabled={!feedback || rating === 0}
                >
                    Submit Feedback
                </button>
            </form>

            {isFeedbackSubmitted && (
                <div className="feedback-popup">
                    <p>Your feedback has been submitted!</p>
                    <button type="button" onClick={closePopup}>
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default FeedbackPage;

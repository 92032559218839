import React, { useState } from "react";
import { Link } from "react-router-dom";
import "@mdi/font/css/materialdesignicons.css";
import api from "../../api/api";
import "./ReportPage.css"; // Import the CSS file

const ReportPage = () => {
    const [downloading, setDownloading] = useState(false);

    const handleShareClick = () => {
        const subject = encodeURIComponent("Share Report");
        window.location.href = `mailto:?subject=${subject}`;
    };

    const handleDownloadPDFReport = async () => {
        setDownloading(true);

        try {
            const pdfBlob = await api.downloadPDFReport();
            const url = window.URL.createObjectURL(pdfBlob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "report.pdf";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading PDF report:", error);
        } finally {
            setDownloading(false);
        }
    };

    const handleFeedbackClick = () => {
        window.location.href = "/feedback";
    };

    return (
        <div className="report-page">
            <h1>Reports</h1>
            <div className="buttons">
                <button onClick={handleShareClick}>
                    <i className="mdi mdi-share-variant"></i> Share
                </button>
                <button onClick={handleDownloadPDFReport}>
                    <i className="mdi mdi-download"></i> Download
                </button>
                <button onClick={handleFeedbackClick}>
                    <i className="mdi mdi-feedback"></i> Feedback
                </button>
            </div>
            {downloading && <div className="loader">Downloading...</div>}
        </div>
    );
};

export default ReportPage;
